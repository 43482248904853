import { FC } from 'react';
import IconProps from './IconProps';

const DistributeHandsIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';
  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.8941 7.67181H20.0047M20.0047 7.67181H19.06C17.1706 5.78408 14.3365 3.89279 12.4471 5.7824M20.0047 7.67181V15.2294M12.4471 5.7824L9.61253 8.61806C9.53756 8.69306 9.50007 8.73056 9.46991 8.76375C8.81466 9.48465 8.81493 10.5855 9.47055 11.3061C9.50072 11.3393 9.53829 11.3768 9.61324 11.4517C9.68821 11.5266 9.7257 11.5641 9.75889 11.5943C10.4796 12.2492 11.5802 12.2492 12.3007 11.5939C12.3339 11.5638 12.3714 11.5263 12.4463 11.4513L13.3914 10.5063L16.6982 13.8124C16.7734 13.8876 16.8111 13.9252 16.8414 13.9586C17.4964 14.6792 17.4964 15.7797 16.8414 16.5003C16.8111 16.5337 16.7734 16.5713 16.6982 16.6465C16.623 16.7217 16.5853 16.7594 16.5521 16.7897C15.8314 17.4448 14.731 17.4448 14.0103 16.7897C13.977 16.7594 13.9393 16.7217 13.8641 16.6465L13.3918 16.1741C12.8767 16.6893 12.6191 16.9468 12.3413 17.0845C11.8127 17.3464 11.192 17.3464 10.6635 17.0845C10.3856 16.9468 10.128 16.6893 9.61294 16.1741C8.9619 17.0422 7.62493 16.9217 7.13967 15.9512L6.77883 15.2294H4.88941M12.4471 5.7824C10.5577 3.89279 7.72353 5.78415 5.83412 7.67187H4.88941M20.0047 15.2294V18.0635H21.8941M20.0047 15.2294H17.3327M4.88941 7.67187H3M4.88941 7.67187V15.2294M4.88941 15.2294V18.0635H3"
        stroke="#3E2234"
        strokeWidth="1.57461"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DistributeHandsIcon;
